import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CapsuleStatus } from '@store/capsule/capsule-status/capsule-status.model';

import { CompanySearch } from './company-search.model';
import { Search, UserPositionFilterOptions } from './search.model';

// export type TagState = EntityState<Tag>

export interface SearchState extends EntityState<CompanySearch> {
  search: Search;
  filters?: { position?: UserPositionFilterOptions };
}

export const initialSearchState: SearchState = {
  search: {
    searchTerm: '',
    showCapsulesICreatedOrPilote: false,
    showCapsulesIActivated: false,
    showCapsulesIParticipate: false,
    showCapsulesIResolved: false,
    showMyFavorites: false,
    showTrendingCapsules: false,
    showPending: false,
    showNews: false,
    showActivated: false,
    showRealised: false,
    showNotResolved: false,
    showArchived: false,
    showOld15Days: false,
    showOld30Days: false,
    showOld45Days: false,
    showOldBydate: {
      startDate: null,
      endDate: null,
      status: CapsuleStatus.CREATED,
    },
    orderByCreatedAtDesc: true,
    orderByCreatedAtAsc: false,
    orderByCapsulePointsDesc: false,
    showCapsulesIValidator: false,
    constellations: [],
    categories: { domainAndThematic: [], tags: [] },
    position: [],
  },
};

export const filtersSearch = [
  'orderByCreatedAtDesc',
  'orderByCreatedAtAsc',
  'orderByCapsulePointsDesc',
];

export const getIsNotInit = (keys: string[], searchParams: Search) =>
  keys.some((key) => {
    if (key !== 'searchTerm' && !filtersSearch.includes(key)) {
      return searchParams[key] === true;
    }
  });

@StoreConfig({
  name: 'search',
})
export class SearchStore extends EntityStore<SearchState> {
  constructor() {
    super(initialSearchState);
  }
}

export const searchStore = new SearchStore();
