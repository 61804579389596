export const environment = {
  production: false,
  test: true,
};
const mockSsoConfig: SsoConfig = {
  ad: {
    enable: true,
    baseUrl: 'https://ad.example.com',
    endpoints: {
      getCurrentUser: '/api/getCurrentUser',
      getAllUser: '/api/getAllUser',
      search: '/api/search',
    },
    auth: {
      user: 'user',
      pass: 'pass',
      workstation: 'workstation',
      domain: 'domain',
    },
    conf: { NODE_TLS_REJECT_UNAUTHORIZED: '0' },
  },
};

export type ProxyConfig = {
  http: string;
  https: string;
};

export const features: FeaturesConfig = {
  workflowPartage: true,
  sso: false,
  enableConstellationManagement: true,
};

export const mainEnvConfig: MainEnvConfig = {
  server: {
    disableSecureTransport: false,
  },
  environment,
  features,
};

export type SsoConfig = {
  ad: {
    enable: boolean;
    baseUrl: string;
    endpoints: { getCurrentUser: string; getAllUser: string; search: string };
    auth: { user: string; pass: string; workstation: string; domain: string };
    conf: { NODE_TLS_REJECT_UNAUTHORIZED: string };
  };
};
export type FeaturesConfig = {
  workflowPartage: boolean;
  sso: false | SsoConfig;
  enableConstellationManagement: boolean;
};

export interface MainEnvConfig {
  server: {
    disableSecureTransport: boolean;
    proxy?: ProxyConfig | false;
  };
  environment: typeof environment;
  features: FeaturesConfig;
}
